





















































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import DataQualityTableRow from "@/components/dataQuality/dataQualityTable/DataQualityTableRow.vue";
import { DataQualityHeader, DataQualityRow } from "@/types/dataQuality/dataQualityTable";

@Component({
  components: {
    DataQualityTableRow,
  },
})
export default class DataQualityTable extends Vue {
  @Prop({ type: Boolean, default: false }) showOverallStatus!: Boolean;
  @Prop({ type: Boolean, default: false }) loading!: Boolean;
  @Prop({ type: Array, default: () => [] }) headers!: DataQualityHeader[];
  @Prop({ type: Array, default: () => [] }) rows!: DataQualityRow[];
  @Prop({ type: Array, default: () => [] }) links!: string[] | null;

  sortBy = "";
  sortDesc = false;

  sort(value: any): void {
    if (this.sortBy === value) {
      if (this.sortDesc) {
        this.sortBy = "";
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortBy = value;
      this.sortDesc = false;
    }
  }
}
