var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.rows,"headers":_vm.headers,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-header":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((_vm.headers),function(h,index){return _c('th',{key:h.text,class:[h.class, {
                'sortable': h.sortable,
                'no-sort': _vm.sortBy !== h.value,
                'first-column': index === 0,
              }],style:({
                'width': h.width ? h.width : 'auto',
                'min-width': h.width ? h.width : 'auto',
              }),on:{"click":function($event){return _vm.sort(h.value)}}},[_c('span',[_vm._v(_vm._s(h.text))]),(h.tooltip !== undefined)?_c('v-tooltip',{attrs:{"bottom":"","color":"black","max-width":"280"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"darkGrey75","size":"medium"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',{staticClass:"tooltip-text"},[_vm._v(_vm._s(h.tooltip))])]):_vm._e(),(h.sortable)?_c('span',[(_vm.sortBy === h.value)?_c('v-icon',{class:{'rotate-icon': _vm.sortDesc},attrs:{"small":""}},[_vm._v(" mdi-arrow-up ")]):_c('v-icon',{staticClass:"sort-icon",attrs:{"small":""}},[_vm._v(" mdi-arrow-up ")])],1):_vm._e(),(h.children)?_c('div',{staticStyle:{"padding-top":"10px"}},_vm._l((h.children),function(child,index){return _c('th',{key:index,staticClass:"children",style:({
                'width': child.width ? child.width : 'auto',
                'min-width': child.width ? child.width : 'auto',
              })},[_vm._v(" "+_vm._s(child.text)+" "),(child.tooltip !== undefined)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"grey","size":"medium"}},on),[_vm._v("mdi-help-circle")])]}}],null,true)},[_c('span',{staticStyle:{"max-width":"300px"}},[_vm._v(_vm._s(child.tooltip))])]):_vm._e()],1)}),0):_vm._e()],1)}),0)])]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [(!_vm.loading)?_c('tr',_vm._l((_vm.headers),function(header,index){return _c('td',{key:index,class:{
                  'first-column': index === 0,
                }},[(item[header.value])?_c('DataQualityTableRow',{attrs:{"dataQualityRow":item[header.value],"type":header.type,"columnName":header.text,"link":_vm.links ? _vm.links[index] : null}}):_vm._e(),_c('div',[(header.children)?_c('v-row',_vm._l((header.children),function(child,index){return _c('v-col',{key:index,staticClass:"pa-0"},[(item[child.value])?_c('DataQualityTableRow',{attrs:{"dataQualityRow":item[child.value],"type":child.type,"link":_vm.links ? _vm.links[index] : null}}):_vm._e()],1)}),1):_vm._e()],1)],1)}),0):_vm._e()]}},(true)?{key:"progress",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody","types":{'table-row': 'table-cell@12'}}})]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }